import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-button-close',
  standalone: true,
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './button-close.component.html',
  styleUrl: './button-close.component.scss',
})
export class ButtonCloseComponent {

  type = input<'primary' | 'invert' | 'info' | 'success' | 'warning' | 'error'>('primary');
  size = input<'small' | 'medium' | 'large'>('small');

}
