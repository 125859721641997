import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ReplaySubject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SvgService {

  private icons = new Map<string, ReplaySubject<string>>();
  private http: HttpClient;

  constructor(private httpBackend: HttpBackend) {
    this.http = new HttpClient(this.httpBackend);
  }

  getSvg(name: string): ReplaySubject<string> | null {

    if (!name) {
      return null;
    }

    if (this.icons.has(name)) {
      return this.icons.get(name) as ReplaySubject<string>;
    }

    const icon = new ReplaySubject<string>(1);
    this.icons.set(name, icon);

    firstValueFrom(this.http.get(`assets/svg/${name}.svg`, {
      responseType: 'text',
    })).then((svg) => {
      icon.next(svg);
    });

    return icon;
  }

}
