import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';

import { ButtonCloseComponent } from '../button-close/button-close.component';
import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-modal',
  standalone: true,
  imports: [ CommonModule, ButtonCloseComponent, InlineSvgComponent ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent {

  titleIcon = input<string>();

  private dialogRef = inject(DialogRef);

  onClose() {
    this.dialogRef.close();
  }
}
