<div class="integer-field" [ngClass]="state()">
  <label class="integer-field-container">
    <input class="integer-field-input" type="text" mask="separator.0" [outputTransformFn]="toFixed" [placeholder]="placeholder()" [(ngModel)]="value"
      [name]="name()">
  </label>
  @if (alert() && state() !== fieldState.Default) {
    <div class="integer-field-alert">
      <ui-inline-svg class="integer-field-alert-icon" [name]="alertIcon()"></ui-inline-svg>
      <span class="integer-field-alert-integer">{{ alert() }}</span>
    </div>
  }
</div>