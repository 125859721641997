import { CommonModule } from '@angular/common';
import {
  Component, computed, input, model,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { provideNgxMask, NgxMaskDirective } from 'ngx-mask';

import { FieldState } from '../../../models/field.model';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-currency-field',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InlineSvgComponent,
    NgxMaskDirective,
  ],
  templateUrl: './currency-field.component.html',
  styleUrl: './currency-field.component.scss',
  providers: [
    provideNgxMask(),
  ],
})
export class CurrencyFieldComponent {
  fieldState = FieldState;
  name = input<string>(`ui-currency-field-${(Math.round((Math.random() * 100000000)).toString(16))}`);
  value = model<string>('');
  state = input<FieldState>(FieldState.Default);
  prefix = input<string>('€');
  placeholder = input<string>('');
  alert = input<string>();

  icon = computed(() => {
    switch (this.state()) {
      case FieldState.Success:
      case FieldState.Info:
        return 'success';
      case FieldState.Invalid:
        return 'error';
      default:
        return '';
    }
  });

  toFixed(value: string | number | undefined | null): number {
    const formattedValue = String(value).split(' ').join('');

    // if (String(value).includes('.') && String(value).split('.').length === 2) {
    //   const decimal = String(value).split('.')[1]?.length;

    //   if (decimal && decimal > 2) {
    //     return Number(parseFloat(formattedValue).toFixed(2));
    //   }
    // }

    return Number(formattedValue);
  }
}
