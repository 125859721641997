import { CommonModule } from '@angular/common';
import { Component, effect, input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { firstValueFrom } from 'rxjs';

import { SvgService } from './svg.service';

@Component({
  selector: 'ui-inline-svg',
  standalone: true,
  imports: [ CommonModule ],
  template: '<div class="wrapper" [innerHTML]="svgIcon"></div>',
  styleUrl: './inline-svg.component.scss',
})
export class InlineSvgComponent {

  name = input<string>('');

  svgIcon?: SafeHtml;

  constructor(private sanitizer: DomSanitizer, private svgService: SvgService) {
    effect(() => this.loadSvg(this.name()));
  }

  async loadSvg(name: string) {
    const svg$ = this.svgService.getSvg(name);

    if (!svg$) {
      return;
    }

    const svg = await firstValueFrom(svg$);

    this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(svg);
  }
}
