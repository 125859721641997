import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-tooltip',
  standalone: true,
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss',
})
export class TooltipComponent {
  text = input.required<string>();
  position = input.required<'top' | 'bottom' | 'start' | 'end'>();
  isOpen = input<boolean>();
}
