import { CommonModule } from '@angular/common';
import {
  Component, computed, input, model,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { provideNgxMask, NgxMaskDirective } from 'ngx-mask';

import { FieldState } from '../../../models/field.model';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-text-field',
  standalone: true,
  imports: [ CommonModule, FormsModule, InlineSvgComponent, NgxMaskDirective ],
  templateUrl: './text-field.component.html',
  styleUrl: './text-field.component.scss',
  providers: [
    provideNgxMask(),
  ],
})
export class TextFieldComponent {
  fieldState = FieldState;
  name = input<string>(`ui-text-field-${(Math.round((Math.random() * 100000000)).toString(16))}`);
  value = model<string>('');
  state = input<FieldState>(FieldState.Default);
  placeholder = input<string>('');
  alert = input<string>();
  mask = input<string>('');
  icon = input<string>('');

  alertIcon = computed(() => {
    switch (this.state()) {
      case FieldState.Success:
      case FieldState.Info:
        return 'success';
      case FieldState.Invalid:
        return 'error';
      default:
        return '';
    }
  });
}
