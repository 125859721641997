<div class="password" [ngClass]="state()">
  <div class="password-container">
    <input
      class="password-input"
      [placeholder]="placeholder()"
      [type]="showPassword ? 'text' : 'password'"
      [(ngModel)]="value"
      [name]="name()"
    />
    <button class="password-button" type="button" (click)="onShowPassword()">
        <ui-inline-svg class="password-icon" [name]="showPassword ? 'hidden-on' : 'hidden-off'"></ui-inline-svg>
    </button>
  </div>
  @if (alert() && state() !== fieldState.Default) {
    <div class="password-alert">
      <ui-inline-svg class="password-alert-icon" [name]="icon()"></ui-inline-svg>
      <span class="password-alert-text">{{ alert() }}</span>
    </div>
  }
</div>
