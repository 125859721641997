<div class="date-field" [ngClass]="state">
  <div class="date-field-container">
    <input class="date-field-input" type="date" [(ngModel)]="value" [name]="name()">
  </div>
  @if (alert() && state() !== fieldState.Default) {
    <div class="date-field-alert">
      <ui-inline-svg class="date-field-alert-icon" [name]="icon()"></ui-inline-svg>
      <span class="date-field-alert-text">{{ alert() }}</span>
    </div>
  }
</div>
