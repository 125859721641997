<div class="currency-field" [ngClass]="state()">
  <div class="currency-field-container">
    <input class="currency-field-input" type="text" prefix="$ " mask="separator.2" thousandSeparator="." decimalMarker="," [(ngModel)]="value" [placeholder]="placeholder()" [name]="name()">
  </div>
  @if (alert() && state() !== fieldState.Default) {
    <div class="currency-field-alert">
      <ui-inline-svg class="currency-field-alert-icon" [name]="icon()"></ui-inline-svg>
      <span class="currency-field-alert-text">{{ alert() }}</span>
    </div>
  }
</div>
