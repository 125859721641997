import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Toast, ToastType } from '../../models/toast.model';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts$!: Observable<Toast[]>;

  private toastsSubject = new BehaviorSubject<Toast[]>([]);

  constructor() {
    this.toasts$ = this.toastsSubject.asObservable();
  }

  showToast(toast: Toast): void {
    if (!toast.type) {
      toast.type = ToastType.Warning;
    }

    const currentToasts = this.toastsSubject.getValue();
    this.toastsSubject.next([ ...currentToasts, toast ]);

    if (toast.duration) {
      // Remove toast after x seconds
      setTimeout(() => this.removeToast(toast), (toast.duration * 1000));
    }
  }

  removeToast(toast: Toast): void {
    const currentToasts = this.toastsSubject.getValue();
    const index = currentToasts.indexOf(toast);

    if (index >= 0) {
      const updatedToasts = currentToasts.slice();
      updatedToasts.splice(index, 1);
      this.toastsSubject.next(updatedToasts);
    }
  }
}
