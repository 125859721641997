<div class="mail" [ngClass]="state()">
  <div class="mail-container">
    <input class="mail-input" type="email" [(ngModel)]="value" [placeholder]="placeholder()" [name]="name()" (change)="verifyEmail()">
  </div>
  @if (alert() && state() !== fieldState.Default) {
    <div class="mail-alert">
      <ui-inline-svg class="mail-alert-icon" [name]="icon()"></ui-inline-svg>
      <span class="mail-alert-text">{{ alert() }}</span>
    </div>
  }
</div>
