import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

export enum ButtonColors {
  PrimaryOnLight = 'primaryOnLight',
  PrimaryOnDark = 'primaryOnDark',
  SecondaryOnLight = 'secondaryOnLight',
  SecondaryOnDark = 'secondaryOnDark',
  GhostOnLight = 'ghostOnLight',
  GhostOnDark = 'ghostOnDark',
  ContrastOnLight = 'contrastOnLight',
  ContrastOnDark = 'contrastOnDark',
  SemanticInfo = 'info',
  SemanticSuccess = 'success',
  SemanticWarning = 'warning',
  SemanticError = 'error'
}

@Component({
  selector: 'ui-button',
  standalone: true,
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {

  color = input<ButtonColors>(ButtonColors.PrimaryOnLight);
  large = input<boolean>(false);
  disabled = input<boolean>(false);
  icon = input<string>('');
  hug = input<boolean>(false);
  centered = input<boolean>(false);
  inverted = input<boolean>(false);

  cssClasses = computed(() => {
    return {
      'has-icon': this.icon(),
      'is-large': this.large(),
      'is-centered': this.centered(),
      'is-inverted': this.inverted(),
      disabled: this.disabled(),
      hug: this.hug(),
      [this.color()]: true,
    };
  });
}
