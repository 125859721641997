import { CommonModule } from '@angular/common';
import {
  Component, computed, input, model,
} from '@angular/core';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-checkbox',
  standalone: true,
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
})
export class CheckboxComponent  {

  name = input<string>(`ui-checkbox-${(Math.round((Math.random() * 100000000)).toString(16))}`);
  value = model<boolean | 'partial'>(false);
  iconName = computed(() => {
    if (this.value() === 'partial') {
      return 'checkbox-indeterminate';
    }

    return this.value() ? 'checkbox-selected' : 'checkbox-unselected';
  });

  toggleChecked() {
    this.value.set(!this.value());
  }
}

