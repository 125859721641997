import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { Toast, ToastType } from '../../models/toast.model';
import { ButtonComponent } from '../button/button.component';
import { ButtonCloseComponent } from '../button-close/button-close.component';
import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

import { ToastService } from './toast.service';

@Component({
  selector: 'ui-toast',
  standalone: true,
  imports: [ CommonModule, InlineSvgComponent, ButtonComponent, ButtonCloseComponent ],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent implements OnInit, OnDestroy {
  toasts: Toast[] = [];
  toastType = ToastType;

  private subscription = new Subscription();

  constructor(
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.subscription = this.toastService.toasts$.subscribe((toasts: Toast[]) => {
      this.toasts = toasts;
    });
  }

  removeToast(toast: Toast) {
    this.toastService.removeToast(toast);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
