export enum FieldState {
  Default = 'default',
  Success = 'success',
  Invalid = 'invalid',
  Info = 'info'
}

export type DropdownValue = string | number | boolean | Date;

export interface DropdownOption<T = DropdownValue> {
  label: string;
  value: T;
}

export enum FieldType {
  Select = 'select',
  Text = 'text',
  Integer = 'integer',
  Datetime = 'datetime',
  Email = 'email',
  Phone = 'phone',
  Currency = 'currency',
  Boolean = 'boolean',
  Date = 'date',
  Time = 'time'
}
