export interface Toast {
  type?: ToastType;
  text: string;
  buttonText?: string;
  duration?: number;
}

export enum ToastType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}
