<div class="container">
  <header class="header">
    <div class="title">
      @if (titleIcon()) {
        <ui-inline-svg [name]="titleIcon() ?? ''"></ui-inline-svg>
      }
      <ng-content select="h5"></ng-content>
    </div>
    <ng-content select="h2"></ng-content>
    <ui-button-close type="invert" size="large" (click)="onClose()"></ui-button-close>
  </header>
  <main class="main">
    <ng-content select="ng-container"></ng-content>
  </main>
</div>
